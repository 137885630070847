<template>
  <div class="case-view1-main">
    <view2 ></view2>
  </div>
</template>

<script>
import view2 from "@/views/Core/components/view2";

export default {
  name: "index",
  components: {
    view2,
  }
}
</script>

<style lang="scss" scoped>
.case-view1-main{
}
</style>
