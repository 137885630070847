<template>
  <div class="alarm">
    <div class="flex justify-content-center " style="margin-bottom: 50px;">
      <kcTitle2 title="智能硬件" en="Intelligent Hardware"></kcTitle2>
    </div>
    <div v-for="(item,index) in dataList.slice((pageNum - 1) * pageSize, pageNum * pageSize)" :key="index">
      <div class="cont font-bold">
        <div class="flex justify-content-center title">{{ item.name }}</div>
        <div class="flex flex-warp">
          <div style="width: calc(100% / 3)" v-for="(ele,i) in item.img" :key="i">
            <div class="content flex flex-column align-items-center justify-content-center">
              <div class="flex justify-content-center mt20 mb10" one>
                <img :src="ele.imgUrl" width="50%" >
              </div>
              <div>{{ ele.title }}</div>
            </div>
          </div>

        </div>
      </div>
      <div class="line" v-if="index % 2 == 0"></div>
    </div>
    <div class="flex justify-content-center sheet" style="width: 100%;margin-bottom: 30px">
      <div class="flex">
        <div class="flex justify-content-start align-items-center">
          <img src="../../../assets/images/News/prev.png" @click="prev" width="50%"/>
        </div>
        <div class="flex justify-content-center align-items-end">
          <div class="out-pageNum">
            <div :style="{transform: `translateY(${(pageNum-1)*-20}px)`}" class="flex flex-column num-content">
              <div v-for="item in totalPageNum" :key="item" class="pageNum">{{ getNum(item) }}</div>
            </div>
          </div>
          <div> /</div>
          <div class="totalPageNum">{{ getNum(totalPageNum) }}</div>
        </div>
        <div class="flex justify-content-end align-items-center">
          <img src="../../../assets/images/News/next.png" @click="next" width="50%"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "view2",
  data() {
    return {
      pageSize: 2,
      pageNum: 1,
      totalPageNum: 0,
      dataList: [
        {
          name: '智能饲喂器',
          img: [{
            imgUrl: require('../../../assets/images/Hardware/img/NF01.png'),
            title: '哺乳母猪智能饲喂器 NF01'
          }, {
            imgUrl: require('../../../assets/images/Hardware/img/NF02.png'),
            title: '妊娠母猪智能饲喂器 NF02'
          },{
            imgUrl: require('../../../assets/images/Hardware/img/NF03.png'),
            title: '保育育肥智能饲喂器 NF03'
          },{
            imgUrl: require('../../../assets/images/Hardware/img/NF03-1.png'),
            title: '保育育肥智能饲喂器 NF03'
          },{
            imgUrl: require('../../../assets/images/Hardware/img/NF10.png'),
            title: '保育育肥智能饲喂器 NF10'
          },

          ]
        },{
          name: '家禽专用',
          img: [{
            imgUrl: require('../../../assets/images/Hardware/img/F80.png'),
            title: 'Future 80'
          }, {
            imgUrl: require('../../../assets/images/Hardware/img/F80E.png'),
            title: 'Future 80E'
          }, {
            imgUrl: require('../../../assets/images/Hardware/img/F100.png'),
            title: 'Future 100'
          }, {
            imgUrl: require('../../../assets/images/Hardware/img/FX.png'),
            title: 'Future x'
          },]
        }, {
          name: '家畜专用',
          img: [{
            imgUrl: require('../../../assets/images/Hardware/img/K9.png'),
            title: '智能环控器 K9'
          }, {
            imgUrl: require('../../../assets/images/Hardware/img/K16.png'),
            title: '智能环控器 K16'
          }, {
            imgUrl: require('../../../assets/images/Hardware/img/K16.png'),
            title: '智能环控器 K16 Pro'
          }, {
            imgUrl: require('../../../assets/images/Hardware/img/K20.png'),
            title: '智能环控器 K20'
          },]
        }, {
          name: '集中报警器',
          img: [{
            imgUrl: require('../../../assets/images/Hardware/img/A1.png'),
            title: '守护报警器 A1'
          }, {
            imgUrl: require('../../../assets/images/Hardware/img/A2.png'),
            title: '数据中心报警器 A2'
          }, {
            imgUrl: require('../../../assets/images/Hardware/img/A3.png'),
            title: '网络报警器 A3'
          }]
        }, {
          name: '传感器',
          img: [{
            imgUrl: require('../../../assets/images/Hardware/img/nh3.png'),
            title: 'NH₃ 传感器'
          }, {
            imgUrl: require('../../../assets/images/Hardware/img/co2.png'),
            title: 'CO₂ 传感器'
          },{
            imgUrl: require('../../../assets/images/Hardware/img/two.png'),
            title: 'CO₂&湿度 传感器'
          },{
            imgUrl: require('../../../assets/images/Hardware/img/np.png'),
            title: '静压 传感器'
          },{
            imgUrl: require('../../../assets/images/Hardware/img/humi.png'),
            title: '湿度 传感器'
          },{
            imgUrl: require('../../../assets/images/Hardware/img/temp.png'),
            title: '温度 传感器'
          },]
        },  {
          name: '其他硬件',
          img: [
            {
              imgUrl: require('../../../assets/images/Hardware/img/FD101.png'),
              title: '播种控制器 FD101'
            }, {
              imgUrl: require('../../../assets/images/Hardware/img/DC1Pro.png'),
              title: '刮粪控制器 DC1Pro'
            }, {
              imgUrl: require('../../../assets/images/Hardware/img/F101Pro.png'),
              title: '料线控制器 F101Pro'
            },{
              imgUrl: require('../../../assets/images/Hardware/img/F201Pro.png'),
              title: '料线控制器 F201Pro'
            },{
              imgUrl: require('../../../assets/images/Hardware/img/F301Pro.png'),
              title: '料线控制器 F301Pro'
            },
            {
              imgUrl: require('../../../assets/images/Hardware/img/D1000.png'),
              title: '数字风机控制器 D1000'
            },{
              imgUrl: require('../../../assets/images/Hardware/img/AW.png'),
              title: '水线管理器'
            },{
              imgUrl: require('../../../assets/images/Hardware/img/G1.png'),
              title: '智能网关 G1'
            },{
              imgUrl: require('../../../assets/images/Hardware/img/H2.png'),
              title: '控干房控制器 H2'
            },
            {
              imgUrl: require('../../../assets/images/Hardware/img/S1.png'),
              title: '智能采集器 S1'
            },{
              imgUrl: require('../../../assets/images/Hardware/img/W3.png'),
              title: '小窗控制盒 W3'
            },

          ]
        }
      ]
    }
  },
  mounted() {
    let num = 0
    if ((this.dataList.length % this.pageSize) == 0) {
      num = parseInt(this.dataList.length / this.pageSize)
    } else {
      num = parseInt(this.dataList.length / this.pageSize) + 1
    }
    this.totalPageNum = num
  },
  methods: {
    getNum(num) {
      let value = 0
      if (num < 10) {
        value = "0" + num
        return value
      } else {
        value = num
        return value
      }
    },
    prev() {
      if (this.pageNum != 1) {
        this.pageNum = parseInt(this.pageNum) - 1
      }
    },
    next() {
      if (this.pageNum != this.totalPageNum) {
        this.pageNum = parseInt(this.pageNum) + 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes boost {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

.alarm {
  .cont {
    padding: 0 100px;

    .title {
      font-size: 24px;
      font-weight: 600;
      color: #000000;
    }

    .content {
      width: calc(100% - 30px);
      margin-bottom: 50px;

      &:hover {
        img {
          animation: boost 0.7s;
          animation-fill-mode: forwards;
        }
      }

      & > div[one] {
        padding-top: 40px;
        padding-bottom: 40px;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
        font-size: 16px;
        color: #000000;
      }
    }
  }

  .line {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    border: 1px solid rgba(153, 153, 153, 0.2);
  }

  .sheet {
    .out-pageNum {
      height: 21px;
      width: 20px;
      overflow-y: hidden;

      .num-content {
        transition: all 0.7s;

        .pageNum {
          height: 20px;
          font-size: 16px;
          color: #333333;
        }
      }

    }

    .totalPageNum {
      font-size: 12px;
      color: #999999;
    }
  }
}
</style>
